.compnyDivHead ul {
  list-style: none;
  padding: 0;
}

.compnyDivHead ul li a {
  text-decoration: none;
  color: #ffffff;
}

.compnyDivHead ul li {
  margin-bottom: 1rem;
}

.compnyDivHead h5 {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.footerIconsSocial {
  margin: 1.5rem 0;
}

.footerIconsSocial ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footerIconsSocial ul li img {
  width: 2.5rem;
}

.footer_container {
  border-top: 3px solid;
  border-image: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  border-image-slice: 1;
  background: url("../assets/images/footernewbg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem 0;
  width: 100%;
}

.newsLttrInput {
  background-color: transparent;
  z-index: 0;
  padding: 0.7rem 1rem;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  border-color: transparent;
  color: #ffffff;
  font-weight: 500;
  width: 80%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.newsLttrInput::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  /* top: -50%; */
  width: 200%;
  height: 1200%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%100%, 50%50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(
    270deg,
    #76ff01 0.73%,
    #edff0b 18%,
    #15ff3e 33.06%,
    #17ffff 49.89%,
    #1700f5 67.61%,
    #ec00e7 85.77%
  );
  animation: bgRotate 4s linear infinite;
}

.newsLttrInput::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 3px;
  top: 3px;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  background: rgb(0, 0, 0);
  border-radius: 20px;
}

.newsLttrInput input {
  width: 100%;
  background-color: #000;
  border: none;
  outline: none;
  color: #ffffff;
}

.enterImg {
  width: 2.1rem;
}

.newsLetterDiv h5 {
  margin-bottom: 1rem;
}

.certificationsContianer ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.certificationsContianer ul li img {
  width: 2.7rem;
}

.contACTcONTAIENR h5 {
  margin-bottom: 1.5rem;
}

.contACTcONTAIENR ul {
  list-style: none;
  padding: 0;
}

.contACTcONTAIENR .contactDetail {
  display: flex;
  align-items: flex-start;
  gap: 0.6rem;
  margin-bottom: 0.7rem;
}

.contACTcONTAIENR .contactDetail p {
  margin: 0;
}

.footerRight {
  height: 92%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}

.FlogoDiv {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1200px) {
  .newsLttrInput {
    width: 85%;
  }
}

@media screen and (max-width: 991px) {
  .newsLttrInput {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .newsLttrInput {
    width: 100%;
  }
}

@media screen and (max-width: 655px) {
  .newsLetterDiv h5,
  .compnyDivHead h5,
  .contACTcONTAIENR h5 {
    font-size: 17px !important;
  }

  .compnyDiv p {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .footerRight {
    gap: 2rem;
  }
}

.cache_tag_fttr {
  text-align: center;
}

.ratingsDic {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: center;
}
.ratingsDic p {
  margin: 0;
  margin-top: 0.3rem;
  font-size: 13px !important;
}

.ratingsDic img {
  width: 1.1rem !important;
}

.certiimgTag {
  width: 6.5rem !important;
  height: 4rem !important;
}

.ambitiousBox {
  margin-top: 12px;
  height: 1.5rem !important;
  width: 6.5rem !important;
}

/* ----------------------------------------------------------------------------------------------------------------- */

.footer {
  position: relative;
  margin-top: 8rem;
}

.footer-container {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 10%;
  top: 0%;
}

.thing {
  width: 400px;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.thing::before,
.thing::after {
  content: "";
  z-index: 1;
  position: absolute;
}

/* .thing::before {
  border-top: 1px solid #000;
  left: -50% !important;
  right: -50%;
  top: -1% !important;
  height: 2rem;
} */

.thing::after {
  border-radius: 70% !important;
  left: 98px;
  right: 98px;
  height: 320px;
  border: 3px solid #ec00e7;
  top: -234px;
  background-color: #000;
}

.circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  top: -4rem;
  /* Adjust this value as needed */
  /* left: calc(- 25px); */
  right: 34%;
  z-index: 20;
  background-image: url("../assets/images/Finalgetstrted.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.footer-1 {
  display: flex;
  justify-content: end;
}

@media only screen and (max-width: 600px) {
  .center-3 {
    display: flex;
    justify-content: center;

    height: auto;
  }
  .certificationsContianer ul {
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 510px) {
  .footer-container {
    right: 7% !important;
  }

  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: -3.5rem;
    /* Adjust this value as needed */
    /* left: calc(- 25px); */
    right: 37.5%;
    z-index: 20;
    background-image: url("../assets/images/Finalgetstrted.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    animation: spin 3s linear infinite;
  }

  .thing::after {
    border-radius: 70% !important;
    left: 110px;
    right: 110px;
    height: 320px;
    border-bottom: 3px solid #ec00e7;
    top: -260px;
    background-color: #000;
  }

  .arrrowCircle {
    width: 60px;
    z-index: 21;
    position: absolute;
    right: 42%;
    top: -19% !important;
  }
}

@media only screen and (max-width: 450px) {
  .footer-container {
    right: 3% !important;
  }
}

@media only screen and (max-width: 430px) {
  .footer-container {
    right: 2% !important;
  }
}

@media only screen and (max-width: 400px) {
  .footer-container {
    right: -2% !important;
  }
}

@media only screen and (max-width: 380px) {
  .footer-container {
    right: -3% !important;
  }
}

@media only screen and (max-width: 360px) {
  .footer-container {
    right: -7% !important;
  }
}

.contactDetail img {
  width: 1.5rem;
}

.arrrowCircle {
  width: 64px;
  z-index: 21;
  position: absolute;
  right: 42%;
  top: -14%;
}

.arrrowCircle img {
  width: 100%;
}

.cursor_pointer {
  cursor: pointer;
}

/* Footer stylings */

.footer_head {
  /* background-image: url("../assets/images/footer_bg.png"); */
  margin-top: 4rem;
}

.contact_head h4,
.contact_head p,
.contact_head li {
  color: #eb7c7c;
}

.contact_list li {
  padding: 0;
}

.contact_list li i {
  color: #fff;
}

.contact_list li .phone_pink,
.contact_list li .email_pink {
  width: 1.2rem;
}

.contact_list li .place_pink {
  width: 1rem;
}

footer .working table {
  width: 100%;
}

footer .working h4,
footer .working table td {
  color: #88f5f5;
  line-height: 2;
}

.footer_links h4,
.footer_links li a {
  color: #deaa3a;
  padding: 0;
}

li {
  list-style: none;
}

footer .row {
  padding: 3rem 0.5rem !important;
}

.column_1,
.column_2,
.column_3,
.column_4 {
  /* max-width: 450px; */
  height: fit-content;
  margin: 0 auto;
  font-family: "jmh_typewriterregular";
}

.column_3 {
  max-height: 350px;
}

ul {
  padding: 0;
}

.column_3 .footer_link_img img {
  max-width: 70%;
  position: absolute;
  right: 0;
}

.link_bg {
  max-width: 270px;
  height: inherit;
}

.footer_link_img .footer_links {
  /* position: absolute; */
  top: 0;
  left: 0;
  z-index: 10;
}

.social_icons_footer ul {
  padding: 0;
  display: flex;
}

.social_icons_footer ul img {
  margin-right: 1.5rem;
  width: 2.3rem;
}

.insta_img_wrapper img {
  width: 75%;
}

@media screen and (max-width: 991px) {
  .insta_img_wrapper img {
    width: 66%;
  }
}

@media screen and (max-width: 768px) {
  .footer_head {
    margin-top: 0;
  }

  .column_1,
  .column_2,
  .column_3 {
    max-width: 400px;
  }

  .insta_img_wrapper img {
    width: 100%;
  }

  .contact_head ul li {
    font-size: 15px;
    white-space: nowrap;
  }
}

.TeamFoottr button {
  color: #deaa3a;
  border: none;
  background-color: transparent !important;
}
/*-----footer Check box-----*/
.FooterCheckBxx span {
  font-size: 14px;

  padding-left: 5px;
}
.FooterCheckBxx {
  padding-top: 10px;
}
.ffttrBoxx {
  width: 79.9%;
  padding-left: 10px;
  align-items: start !important;
}
/*--------footer start rating---------*/

.rating {
  margin-top: -10%;
  display: flex;

  justify-content: center;
}

.rating > input {
  display: none;
}

.rating > label {
  position: relative;
  width: 1.1rem;
  font-size: 1rem;
  color: #ffd700;
  cursor: pointer;
}
.abmiBoxImg {
  height: 100% !important;
  width: 100%;
  margin-top: 1rem;
}
.abmiBoxImg img {
  height: 1.6rem !important;
  width: 2.1rem;
}
.abmiBoxImg2 {
  margin-top: 10px !important;
}

.rating > label::before {
  content: "\2605";
  position: absolute;
  opacity: 1;
}
.half {
  position: relative;
  overflow: hidden;
}

.half:before {
  content: '★';
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 37%;
  color: #ffd700;
}

/* .rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
} */

/* .rating > input:checked ~ label:before {
  opacity: 1;
}

.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
} */

@media only screen and (max-width: 1000px) {
  .certificationsContianer ul {
    gap: 1.2rem;
  }
  .rating > label {
    width: 0.8rem;
    font-size: 0.8rem;
  }
  .ratingsDic p {
    font-size: 10px !important;
  }
  .googleRatingTxt {
    margin-left: 13px;
  }

  .abmiBoxRatingTxt p {
    margin-right: 0.5rem;
  }
}
.ggle {
  margin-left: -17%;
}

.cookie_btn {
  background-color: transparent;
  z-index: 0;
  padding: 0.4rem 1.2rem;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  border-color: transparent;
  color: #ffffff;
  font-weight: 500;
}

.cookie_btn::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -70%;
  top: -70%;
  width: 250%;
  height: 550%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%100%, 50%50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(
    270deg,
    #76ff01 0.73%,
    #edff0b 18%,
    #15ff3e 33.06%,
    #17ffff 49.89%,
    #1700f5 67.61%,
    #ec00e7 85.77%
  );
  animation: bgRotate 4s linear infinite;
}

.cookie_btn::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: black;
  border-radius: 20px;
}