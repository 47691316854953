@keyframes hueEffect {
  0% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(180deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.cr_v h5 {
  font-size: 40px;
  margin: auto;
}

.ocr_cc {
  display: flex;
  display: contents;
  align-content: center;
}

.o_core_v h3 {
  font-size: 64px;
  color: grey;
}

.aboutbckgrd {
  background-image: url("../assets/images/aboutttttttt.png");
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  animation: hueEffect 4s infinite;
  display: flex;
  align-items: center;
}

.top_txt_bg h1 {
  font-size: 74px !important;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
}

.top_txt_bg p {
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
}

.who_we {
  padding-top: 50px;
  padding-bottom: 25px;
}

.who_we p {
  text-align: left;
}

.ach_txt h3 {
  text-align: left;
}

.head_txt_btm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.hist_prt {
  background: linear-gradient(311.81deg, #33afc6 12.79%, #116676 73.1%);
  padding: 65px;
}

.hist_img {
  position: relative;
}

.box_hist1 {
  position: inherit;
  z-index: 1;
  width: 100%;
}

.box_hist {
  position: absolute;
  bottom: -10%;
  right: -8%;
  z-index: 0;
  background: #01e3eb;
}

.hist_txt {
  display: flex;
  align-items: center;
  height: 100%;
}

.ourCoreValuesCOntainer {
  margin: 3rem 0;
}

.cr_v {
  text-align: center;
  margin-bottom: 1.5rem;
}

.ach_txt {
  margin-bottom: 1.5rem;
  margin-top: 3rem;
}

.log_grd {
  margin: auto;
}

.jttmCOntianaer {
  margin: 3rem 0;
}

.about_pg {
  background-color: #434343;
  padding: 1.5rem 1.5rem;
  border-radius: 25px;
}

.about_pg p {
  margin: 0;
}

.jttmCOntianaerImg img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .top_txt_bg h1 {
    font-size: 50px !important;
  }

  .hist_prt {
    padding: 1.5rem;
  }
}

@media screen and (min-width: 576px) {
  .ourCoreValuesCOntainer {
    margin: 5rem 0;
  }
}

@media screen and (max-width: 576px) {
  .top_txt_bg h1 {
    font-size: 35px !important;
  }
  .top_txt_bg p {
    font-size: 14px;
  }

  .head_txt_btm {
    margin-bottom: 3rem;
  }
  .hideimgafterSm {
    display: none !important;
  }

  .aboutbckgrd {
    height: 50vh !important;
  }

  .who_we {
    padding-top: 20px !important;
  }
}

@media screen and (max-width: 450px) {
  .top_txt_bg h1 {
    font-size: 24px !important;
  }
}
