.igts_heading {
padding-top:80px ;
padding-bottom: 80px;
padding-left: 0px;
padding-right: 0px;
}

.igts_Lhead {
    font-size: 40px;
    padding-top: 15px;
}

.igts_Rhead {
    font-size: 18px;
}

.grp_img {
    background: linear-gradient(180deg, #00DAD9 0%, #3876DE 100%);
    padding: 35px;
    width: 100% auto;
    height: 100% auto;
}

.grp_img img {
    width: 100%;
    height: 100%;
}

.image-grid {
    margin: 3rem auto;
}

.image-item {
    flex: 1;
    text-align: center;
}

.bttm_img {
    width: 100%;
    padding-bottom: 20px;
}

.bttm_txt {
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
    width: 95%;
}

@media screen and (max-width: 576px) {
    .igts_heading {
        padding-top: 50px;
        padding-bottom:50px;
    }

    .grp_img {
        padding: 1rem;
    } 
} 
