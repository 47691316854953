.Casestudycard {
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 5rem auto;
  padding: 1rem;
  transition: all 0.3s ease;
}

.Casestudycard:hover {
  background-color: #333333;
}

.Casestudycard-body {
  text-align: center;
}

.caseCardImg {
  width: 100%;
  border-radius: 10px;
}

.caselogo {
  width: 100%;
}

.card_btn {
  border: 3px solid;
  border-image-source: linear-gradient(90.65deg,
      #0b6b6b 2.4%,
      rgba(0, 0, 0, 0) 102.98%);
}

.Casestudytxt {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.casescard {
  background-color: transparent;
  background-clip: padding-box;
  position: relative;
  border-radius: 120px !important;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 120px !important;
}

.casescard::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 3px;
  background: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.btn_H {
  justify-content: center;
  gap: 10px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  color: white;
  background-color: black;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: white;
  border-radius: 40px 40px 40px 40px;
  padding: 13px 24px 13px 28px;
}

.Casestudy_heading {
  background: white;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.heading_casestudy {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
}

.heading_txt {
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
}

.mid_heading {
  background: linear-gradient(180deg, #00dad9, #3876de);
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
}

.primary_btn:hover .right_arrow {
  background-color: rgba(15, 11, 11, 0.4);
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 5);
  transition: all 0.4s ease-in-out;
}

.caseStudy_container {
  margin: 4rem auto;
}

.contactusHead h4 {
  margin-bottom: 0;
}

.contactusHead {
  margin: 10rem auto;
}

.CasestudytxtCOnt p {
  font-size: 14px;
}

.viewCaseBtnDIv {
  margin-top: 1.5rem;
}

.casescard_text {
  margin: 1.5rem auto !important;
}

@media screen and (max-width: 991px) {
  .heading_casestudy {
    font-size: 45px;
  }
}

@media screen and (max-width: 768px) {
  .casescard_text {
    font-size: 16px;
  }

  .btn_H {
    font-size: 13px;
    border-width: 2px !important;
    padding: 10px 24px 10px 24px;
  }
}

@media screen and (max-width: 576px) {
  .heading_casestudy {
    font-size: 25px;
  }

  .heading_txt {
    font-size: 16px !important;
  }

  .casescard {
    padding: 2rem 0.8rem;
  }

  .casescard_text {
    text-align: left;
  }

  .rowReverse1 {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .rowReverse2 {
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (max-width: 360px) {
  .heading_casestudy {
    font-size: 18px;
  }

  .contactusHead {
    margin: 0 auto !important;
  }

  .caseStudy_container {
    margin: 2rem auto !important;
  }

  .contactusHead h4,
  .heading_txt {
    font-size: 13px !important;
  }

  .Casestudycard {
    margin: 2.5rem auto !important;
  }
}

@media screen and (max-width: 991px) {
  .contactusHead {
    margin: 0 auto !important;
  }
}

.width_image {
  height: 408px;
  width: -webkit-fill-available;
}

.rowReverse1 {
  flex-direction: row-reverse;
}
.Casestudytxt{
  display: block;
}