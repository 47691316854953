.modal-body-color {
    color: black;
    align-items: center;
}

.model-image {
    width: 120px;
    font-size: 25px;
}
.pup-cntnt-width{
    width: 25%;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1000;
}

.pup-cntnt{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 600px;
    width: 100%;
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    border-radius: 20px !important;
}

.pup-cntnt h2 {
    margin-bottom: 10px;
}

.popup-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.popup-overlay.open {
    opacity: 1;
    visibility: visible;
}

.popup-content.open {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

.opn-pup-bttn {
    cursor: pointer;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
}

.opn-pup-bttn:hover {
    background-color: #0056b3;
}

.bttn {
    cursor: pointer;
    padding: 10px;
    border: none;
    border-radius: 4px;
}

.bttn-prmry {
    background-color: #007bff;
    color: #fff;
}

.bttn-scndry {
    background-color: #6c757d;
    color: #fff;
}

.bttn:hover {
    filter: brightness(90%);
}

