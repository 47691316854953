.flex-a,
.primary_btn,
.primary_btn span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.conatct_us_mail a span,
.grid_center {
  display: grid;
  place-content: center;
}

button:focus {
  box-shadow: none;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  background: transparent;
  background-color: initial !important;
  border: none !important;
  padding-right: 8px !important;
}

.primary_btn {
  font-size: 15px;
  leading-trim: both;
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%),
    linear-gradient(180deg, #00dad9 0%, #3876de 100%) !important;
  text-edge: cap;
  border-radius: 100px;
  color: white;
  border: 0px solid transparent;
  display: flex !important;
  gap: 0.5rem;
  transition: all 0.8s ease-in;
  padding: 0.6rem 1.4rem;
  align-items: center !important;
}

.primary_btn p {
  margin: 0;
  white-space: nowrap;
}

.primary_btn span,
.primar_btn span::before,
.primar_btn:hover span::before {
  border-radius: 100%;
  transition: 0.8s ease-in-out;
}

.primary_btn:hover span {
  transition: 0.8s ease-in-out;
  margin-left: 15px;
}

.primary_btn:hover .spancheckmaker {
  background-color: rgba(15, 11, 11, 0.4);
  border-radius: 50%;
  padding: 0.1rem 0.2rem 0.2rem 0.2rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 5);
  transition: all 0.4s ease-in-out;
}

.black_bg_white_text {
  background-color: #000;
  color: #fff;
}

.contactusHead h3 {
  font-weight: 600 !important;
}

.contactusHead {
  text-align: center;
  margin-bottom: 3rem;
}

.contactusForm_container {
  margin: 4rem auto;
}

.contactusInput input,
.contactusInput select {
  height: 40px;
  font-size: 15px;
  padding: 0.5rem;
  border: 2px solid transparent;
  background-clip: padding-box;
  border: 2.44px solid;
  border-image-source: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  border-image-slice: 1;
}
.contactusInput input,
.contactusInput select:focus {
  background: transparent !important;
  color: #ffffff !important;
}

.contactusInput .react-international-phone-input-container {
  height: 40px;
  font-size: 15px;
  padding: 0 0.5rem;
  border: 2px solid transparent;
  background-clip: padding-box;
  border: 2.44px solid;
  border-image-source: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  border-image-slice: 1;
}

.react-international-phone-input-container .react-international-phone-input {
  border-left: 2.44px solid transparent !important;
  border-image-source: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  background: transparent !important;
  border-image-slice: 1;
  background-clip: padding-box;
  background-color: initial !important;
  border: none !important;
  border-left: 2px solid #666 !important;
  width: 100% !important;
}

.contactusInput textarea {
  font-size: 15px;
  padding: 0.5rem;
  border: 2px solid transparent;
  background-clip: padding-box;
  border: 2.44px solid;
  border-image-source: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  border-image-slice: 1;
  outline: none;
}

.contactusInput textarea:focus {
  background: transparent !important;
  color: #ffffff;
}

.contactusbTN button {
  color: #ffffff;
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%),
    linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  width: 150px;
  border-radius: 30px;
  border: none;
}

.contactusbTN button:hover {
  background-color: #0056b3;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  /* Smoother and more pronounced shadow */
  transform: scale(1.05);
}

.what_heading {
  color: white !important;
}
/* background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 500;
} */

.contactusbTN {
  margin: 3rem auto;
}

.happen_nxt_heading {
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.text-white {
  color: white !important;
}

.What_happen_nxt_heading {
  border-bottom: 3px solid transparent;
  background-clip: padding-box;
  border-image-source: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  );
  border-image-slice: 1;
  width: fit-content;
  padding-bottom: 0.5rem;
}

.What_happen_nxt_heading_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsnext_container {
  margin: 2rem auto;
}

.whatsnextText {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
}

.whatsnextText p {
  margin: 0;
  font-weight: 500;
}

.whatsnextText .numbox {
  background: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  font-weight: 500;
  color: #ffffff;
  height: fit-content;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.whatsnextTextwrapper {
  margin: 4rem auto;
}

.emptyBook {
  height: 100px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .contactusInputName {
    margin-bottom: 1.5rem;
  }
}

.modal-header {
  justify-content: flex-end !important;
  border-bottom: none !important;
}

.closeModay {
  border: none !important;
  background-color: transparent;
}

.closeFooterModal {
  background: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  ) !important;
}

.form-select {
  background-color: #000 !important;
  color: white;
}

.contactus_container {
  background: url("../assets/images/contctImg.png");
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}
.FooterCheckBxx {
  margin-bottom: 10px;
  text-align: center;
}
