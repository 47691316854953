.careerCnctHeadLeft ul {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.careerCnctHeadLeft ul .careerIcons1,
.careerCnctHeadLeft ul .careerIcons2,
.careerCnctHeadLeft ul .careerIcons3 {
  width: 2.8rem;
}

.careerCnctHeadLeft ul .careerIcons1 img,
.careerCnctHeadLeft ul .careerIcons2 img,
.careerCnctHeadLeft ul .careerIcons3 img {
  width: 100%;
}

.careerCnctHeadLeft ul .careerIcons1:hover {
  cursor: pointer;
}

.careerCnctHeadLeft ul .careerIcons2:hover {
  cursor: pointer;
}

.careerCnctHeadLeft ul .careerIcons3:hover {
  cursor: pointer;
}

.careerCnctHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.careerCnctHeadLeft h1 {
  font-weight: 600;
}

.careerCnctHeadLeft p {
  font-weight: 400;
  margin-bottom: 0;
}

.careerCnct_container {
  margin: 5rem 0;
}

.emailPhoneD {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.emailD {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  /* background-color: white; */
  border-radius: 50px;
  padding: 0.4rem;
  padding-right: 1rem;
  margin-top: 1rem;
  position: relative;
  text-align: center;
}

.emailD::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 2px;
  background: grey;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.emailD img {
  width: 2rem;
}

.emailD p {
  margin: 0;
  font-size: 15px;
  color: white;
}

.careerCardDiv {
  background-color: white;
  padding: 2.5rem;
  border-radius: 15px;
  margin-bottom: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.careerCardDiv:hover {
  transform: scale(1.1);
}

.careerCardHead {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  justify-content: space-between;
}

.careerCardHeadBorder {
  height: 45px;
  width: 2px;
  background: linear-gradient(358.18deg, #c316df 5.27%, #d05f1f 99.39%);
  margin-right: 5px;
}

.careerCardHeadText p {
  width: 80%;
  font-size: 14px;
  margin: 0;
  color: black;
}

.careerCardHead img {
  width: 2.5rem;
}

.careerCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goBtn {
  width: 2rem;
}

.goBtn img {
  width: 100%;
}

a {
  text-decoration: none !important;
}

.careerCardFooter p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.careerFormBtn button {
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  border-radius: 13px;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  padding: 1rem 5rem;
}

.careerFormBtn button h6 {
  margin: 0;
  font-size: 24px;
}

.careerFormBtn button p {
  margin: 0;
  font-size: 16px;
}

.careerFormBtn {
  text-align: center;
  margin-bottom: 6rem;
}

.careerForm {
  margin-top: 6rem;
}

.careerFormHead {
  background: linear-gradient(270deg, #c316df 30%, #d05f1f 94%);
  padding: 1rem 1.5rem;
  border-radius: 6px;
}

.careerFormHead p {
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
  font-size: 24px;
}

.careerFormInputs input {
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 40px;
  border: 2px solid transparent;
  background-clip: padding-box;
  border: 2.44px solid;
  border-image-source: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  border-image-slice: 1;
}

.careerFormInputs input,
.careerFormInputs select,
.inputFormCareer,
.careerFormInputs textarea:focus {
  background: transparent !important;
  color: #ffffff !important;
  outline: none !important;
}

.careerFormInputs select {
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 40px;
  border: 2px solid transparent;
  background-clip: padding-box;
  border: 2.44px solid;
  border-image-source: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  border-image-slice: 1;
}

.careerFormInputs textarea {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #000;
  border: 2px solid transparent;
  background-clip: padding-box;
  border: 2.44px solid;
  border-image-source: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  border-image-slice: 1;
}

.inputFormCareer {
  margin-bottom: 1rem;
}

.mb-3 label {
  margin-bottom: 1rem !important;
}

.CVuPLOAD {
  border: 3.5px dashed white;
  border-radius: 10px;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.3rem !important;
}

.CVuPLOAD label {
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  padding: 0.4rem 1.2rem;
  border-radius: 20px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}

.uploadTextImg {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.uploadTextImg img {
  width: 3rem;
}

.uploadTextImg h6 {
  font-size: 15px;
  margin: 0;
}

.uploadTextImg p {
  font-size: 13px;
  margin: 0;
}

.captchadIV {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.captchaText {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.captchaText label {
  font-weight: 500 !important;
}

.captchaText h6 {
  margin: 0;
  font-weight: 700 !important;
  background: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
}

.captchadIV button {
  background: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  border-radius: 30px;
  padding: 0.6rem 1.5rem;
  font-weight: 500;
  border: none;
  align-self: flex-end;
}

.perksBenefits {
  margin-top: 5rem;
}

.perksBenefitscard {
  border-radius: 10px;
  background: linear-gradient(270deg, #c316df 30%, #d05f1f 94%);
  padding: 1rem;
  height: inherit;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.perksBenefitscard2 {
  border-radius: 10px;
  background-color: whitesmoke;
  color: black;
  padding: 1rem;
  height: inherit;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.perksBenefitsimage {
  margin-bottom: 2rem;
}

.perksBenefitsimage img {
  width: 100px;
  height: 100px;
  margin-top: -3rem;
  border-radius: 50%;
  object-fit: cover;
}

.perksBenefitstext {
  font-size: 14px;
}

.perksBenefitsHead {
  margin: 2rem auto;
}

.perksBenefitsHead h3 {
  font-weight: 600 !important;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .careerCnct {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .careerCnctHeadLeft ul .careerIcons1,
  .careerCnctHeadLeft ul .careerIcons2,
  .careerCnctHeadLeft ul .careerIcons3 {
    width: 3rem;
  }

  .careerCnctHeadLeft p {
    font-size: 14px;
  }

  .careerForm {
    margin-top: 4rem;
  }

  .careerFormBtn {
    margin-bottom: 4rem !important;
  }
}

@media screen and (max-width: 576px) {
  .careerFormBtn button {
    padding: 1rem 2rem !important;
  }

  .careerFormHead p {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 480px) {
  .careerCnctHeadLeft {
    width: 100%;
  }

  .careerCnctHead {
    flex-direction: column;
  }

  .careerCnctHeadLeft ul {
    justify-content: flex-end;
  }

  .emailPhoneD {
    gap: 0.5rem;
    justify-content: space-around;
  }

  .emailD p {
    font-size: 13px;
  }
}

@media screen and (max-width: 400px) {
  .careerCnctHeadLeft {
    width: 100%;
  }

  .careerCnctHead {
    flex-direction: column;
  }

  .careerCnctHeadLeft ul {
    justify-content: flex-end;
  }

  .emailPhoneD {
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .emailD p {
    font-size: 13px;
  }
}

.careerDetails_container {
  margin: 4rem auto;
}

.vacncyheadDiv .vacncyhead_ {
  font-weight: 600;
}

.POXCards {
  margin: 2rem 0;
}

.POXcard {
  background-color: transparent;
  background-clip: padding-box;
  position: relative;
  border-radius: 120px !important;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  border-radius: 120px !important;
}

.POXcard::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 3px;
  background: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.POXcard .POXcard-text {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 22px;
}

.POXcard .POXcard-title {
  font-size: 16px;
  font-weight: 400 !important;
}

.applyvacancyDiv h4,
.careerDetails_container .Dscphead h5 {
  background: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 1.1rem;
}

.careerDetails_container .Dscphead p {
  font-weight: 400;
}

.careerDetails_container .Dscphead button {
  color: #ffffff;
  background: linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  width: 150px;
  border-radius: 30px;
  border: none;
  margin: 2rem 0;
}

.applyvacancyDiv h4 {
  text-align: center;
  margin: auto;
  margin-bottom: 4rem;
}

.applyvacancyDiv {
  margin: 4rem auto;
}

@media screen and (max-width: 768px) {
  .careerDetails_container .Dscphead p {
    font-weight: 400;
    font-size: 13px;
  }

  .applyvacancyDiv {
    margin: 2rem auto;
  }
}

@media screen and (max-width: 600px) {
  .POXcard .POXcard-text {
    font-size: 18px !important;
  }

  .POXcard .POXcard-title {
    font-size: 15px;
    font-weight: 400 !important;
  }
}

@media screen and (min-width: 576px) {
  .careerFormBtn h6 {
    white-space: nowrap;
  }
}

@media screen and (max-width: 900px) {
  .careerCardDiv {
    padding: 2rem 0.8rem !important;
  }

  .careerCardHeadText p {
    width: 100%;
    font-size: 14px;
    margin: 0;
    color: black;
  }
}

@media screen and (min-width: 900px) {
  .careerCardDiv {
    padding: 2.5rem 1rem;
  }
}

select option {
  background-color: #000;
  color: #ffffff;
}

@media screen and (min-width: 991px) {
  .careerCnct,
  .careerDetails_container {
    margin-top: 10rem;
  }

  .insghtContainer {
    margin-top: 6rem;
  }
}

.condition_error2 {
  font-size: 14px;
  font-weight: 500;
  color: red;
}

/* ----------------------------EmployResgistration--------------------------------- */
.check-box-gap {
  gap: 10px;
}

.pfacc-inp {
  margin-bottom: 40px;
}
.details-form {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}
.scroll-icon {
  height: 26px;
  width: 26px;
  cursor: pointer;
  margin-right: 8px;
}
.details-form button {
  text-decoration: none;
  color: black;
  border: none !important;
}
.details-from-input {
  background-color: black;
}
btn-link {
  text-decoration: none;
}
.registration-input {
  line-height: 0px;
}
.details-inp {
  font-weight: 500;
  background-color: white;
}
.accordionBtn,
.accordionBtn:hover,
.accordionBtn:focus,
.accordionBtn:focus-visible,
.accordionBtn:active {
  box-shadow: none !important;
  border: none !important;
  width: 100%;
  text-align: left;
  color: black;
}
.em-text {
  font-size: 18px;
}

.careerFormCheckbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
