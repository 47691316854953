.privacy_policy_container h6 {
  font-weight: 600;
  margin: 2rem 0 1rem 0;
  color: #ffffff !important   ;
}
.privacy_policy_container h1 {
  color: #2f2f2f !important;
  margin: 3rem 0 0 0;
}

.privacy_policy_container p {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}

.privacy_policy_container a {
  color: #800080 !important;
  text-decoration: underline;
}

.privacy_policy_container {
  margin-bottom: 5rem !important  ;
}

.privacy_policy {
  margin-top: 7rem;
}

.privacy_policy h4 {
  margin-bottom: 2rem;
}

.tblDataMargin {
  padding-right: 7px !important;
  margin-right: 7px !important;
}
.TopMarginTBl {
  padding-top: 1rem;

  margin-top: 1rem;
}



