.tsti_card {
  color: black;
  margin: 1.5rem auto;
  border: 2px solid white;
  border-radius: 15px;
  padding: 1rem 0.5rem;
}

.tsti_img {
  width: 100%;
  height: 275px;
  border-radius: 10px;
}

.bx_pd {
  padding: 0px;
}

.TestimonialHead h4 {
  margin-bottom: 0;
}

.col_pd {
  padding: 0px !important;
}

.testi_txt {
  padding-top: 35px;
  /* border: 2px solid white; */
  /* Set the border to red with a 2px width */
  background-color: transparent;
  /* Make the background transparent */
  color: white;
  padding: 25px;
  border-radius: 10px;
}

.txt_h {
  height: 125px;
}

.tsti_image {
  width: 95%;
  height: 100%;
}

.sld_btn_img {
  border-radius: 50px;
}

.slider-btn {
  background-color: transparent;
  /* Make the background transparent */
  color: white;
  box-shadow: none !important;
}

.bttn-pos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* Adjust as needed */
}

.frontBannner_container {
  margin-bottom: 2rem;
}

.contactusHead {
  margin-bottom: 4rem;
}

@media screen and (max-width: 768px) {
  .txt_h {
    height: auto !important;
    font-size: 13px;
  }

  .testi_txt {
    border: none;
  }

  .tsti_card {
    border: 2px solid white;
    border-radius: 10px;
    margin: 1.5rem 0;
  }

  .tsti_colm {
    display: flex;
    align-items: center;
  }

  .tsti_image {
    padding: 10px;
    width: 100%;
  }

  .tsti_image img {
    width: 100%;
  }
}
