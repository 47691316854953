.App {
  display: flex;
  flex-direction: column;
}

* {
  margin: 0;
  box-sizing: border-box;
}




/* Navbar styling End */

/*---Home Page Model 1 ---*/
.custom-modal .modal-dialog {
  margin: 0 auto;
  top: 55%;
  border: 0px solid;
  background-image: linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%);
}
.custom-modal_2 .modal-dialog{
  border: 0px solid;
  background-image: linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%);
}
.HomeeModel .modal-title {
  font-size: 28px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.HomeeModel .beforrrIcn .modal-body::before {
  content: "";
  position: absolute;
  left: -0px;
  top: -3.5rem;
  background-image: url("../assets/images/A91Robotnew.png");
  background-size: 150px 200px;
  width: 150px;
  height: 170px;
  background-repeat: no-repeat;
}


.beforrrIcn .modal-body {
  height: 10px;
}

.HomeeModel .modal-body {
  height: 80px;
  font-family: "Poppins", sans-serif;
}

.HomeeModel .openClssBtn {
  display: flex;
  gap: 10px;
}

.HomeeModel .mngeBttn {
  margin-right: 1rem;
}

.HomeeModel .openClssBtn button {
  background-color: transparent;
  color: black;
  border: 1.5px solid black;
  padding: 0px 1.5rem 0px 1.5rem;
  margin-bottom: 10px;
}

.modal-dialog {
  max-width: 60rem;
}

.HomeeModel .modal-body {
  width: 90%;
  padding-left: 10rem;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.HomeeModel .modal-body span {
  background: linear-gradient(90deg, #eb6a30 2.27%, #e547e7 94.51%);
  -webkit-background-clip: text;
  background-clip: text;
  background-color: transparent;
  color: transparent;
  font-family: "Poppins", sans-serif;
}

.HomeeModel .modal-header {
  padding-left: 10rem;
  display: flex;
  justify-content: space-between !important;
}

.HomeeModel .modal-footer {
  padding-left: 9rem;
  display: flex;
  justify-content: flex-start !important;
  border: none !important;
}

.HomeeModel .modal-footer button {
  font-weight: 300;
  background-color: transparent;
  border: 1.5px solid #666666;
  /* color: red; */
  background: linear-gradient(90deg, #eb6a30 2.27%, #e547e7 94.51%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  border: none;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.accordion-button,
.accordion-button:hover,
.accordion-button:focus,
.accordion-button:focus-visible,
.accordion-button:active {
  box-shadow: none !important;
  border: none !important;
  width: 100%;
  text-align: left;
  /* color: black; */
}

/* .HomeeModel .openBttn:hover,
.HomeeModel .mngeBttn:hover {
  background-color: #f58634;
  color: white;
  border-color: transparent;
}

.HomeeModel .modal-footer button:hover {
  color: #f58634;
} */

.HomeeModel .modal-footer button {
  display: flex;
}

.RightArrww {
  padding-left: 4px;
}

.RightArrww img {
  height: 17px;
  width: 16px;
}

.ConTAccpt {
  color: white;
  position: relative;
  display: flex;
}
.HomeeModel .modal-content{
  background-color: #000;


}
@media only screen and (max-width: 1000px) {
  .HomeeModel .modal-title {
    font-size: 20px;
  }

  .HomeeModel .modal-content {
    width: 98%;
    margin-left: 5px;
    margin-right: 5px;
    margin-right: 30px;
  }

  .HomeeModel .modal-body {
    width: 100%;
    font-size: 14px;
  }

  .HomeeModel .openClssBtn button {
    font-size: 14px;
    padding: 0px 0.7rem 0px 0.7rem;
  }
}

.accordion-button {
  gap: 0.5rem !important;;
}

.accordion-button span {
  font-weight: 500;
  color: #212121 !important;
  white-space: nowrap;
}

@media only screen and (max-width: 580px) {
  .HomeeModel .openClssBtn {
    position: absolute;
    right: 0;
    bottom: 10px;
  }

  .HomeeModel .modal-title {
    font-size: 18px;
  }

  .HomeeModel .modal-footer button,
  .HomeeModel .modal-body {
    font-size: 13px;
  }

  .HomeeModel .openClssBtn button {
    padding: 0px 0.3rem 0px 0.3rem;
    font-size: 13px;
  }

  .HomeeModel .beforrrIcn .modal-body::before {
    top: -2.5rem;
  }
}

@media only screen and (max-width: 486px) {

  .HomeeModel .modal-footer button,
  .HomeeModel .modal-body {
    font-size: 10px;
  }

  .HomeeModel .modal-footer {
    margin-top: 2px;
    padding-left: 0px;
    z-index: 20;
  }
}

/*-------------------------------------------------------------------------------------------*/

/*--Model 2--*/
.modal_2{
  background: black;
}
/* .custom-modal_2 .modal-dialog{
  border: 0px solid;
  background-image: linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%);
} */
.HomeeModel2 .modal-header {
  display: flex;
  justify-content: space-between !important;
}

.HomeeModel2 .modal-title {
  font-weight: 600;
  font-size: 16px !important;
}

.HomeeModel2 .modal-header p {
  display: flex;
  justify-content: space-between !important;
  font-size: 16px !important;
  color: #ff1212;
  font-weight: 600;
}

.HomeeModel2 .modal-body span {
  color: #ff1212;
  font-size: 16px;
  padding-left: 5px;
}

.fottrBtnModl .modal-footer {
  display: flex;
  justify-content: space-between !important;
}

.leftFottrBtn {
  display: flex;
  gap: 10px;
}
.modal_2 {
  background: black;
  border: none;
  color: white;
}
.accordion-button:not(.collapsed){
  background: transparent;
  background: linear-gradient(90deg, #eb6a30 2.27%, #e547e7 94.51%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

}
.modal2_text{
  background: transparent;
  background: linear-gradient(90deg, #eb6a30 2.27%, #e547e7 94.51%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.accordion-button:not(.collapsed)::after{
  background-image: none;
}
.drop{
  color: #163b8a;
}
/* .accpptBtn button,
.RjectBtn button,
.sveSetting {
  color: #fff;
  border: 1.5px solid #666666;
  background-color: transparent;
  border-radius: 6px;
  padding: 0px 1rem 0px 1rem;
  font-weight: 500;
}

.accpptBtn button:hover,
.RjectBtn button:hover:hover,
.sveSetting:hover {
  background-color: #f58634;
  color: white;
  border-color: transparent;
} */

.cook {
  display: flex;
  flex-direction: column;
  position: relative;
}

.cookbbuttn {
  position: relative;
  border: none;
  color: white;
  background-color: transparent;
}

.drppIcnn {
  position: absolute;
  left: 4rem;
  height: 13px;
  bottom: 17px;
  /* background-color: white; */
  /* color: blue; */
  width: 18px;
  margin-bottom: 2px;
}


.cookboxx {
  display: flex;
  flex-direction: column !important;
  gap: 10px;
  position: relative;
  background-color: black;
}

.cookieCont {
  display: flex;
  align-items: center;
  text-align: center;
  height: 2.8rem;
  position: relative;
  /* background-color: #d9d9d9; */
  border-radius: 4px;
}

.cook1,
.cook2,
.cook3,
.cook4 {
  width: 90%;

  display: flex;
  position: relative;
}

.cookieesss {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  font-weight: 600;
}

.cook1 button,
.cook2 button,
.cook3 button,
.cook4 button,
.cook1-content {
  padding-left: 1rem;
}

.cook1-content {
  border-radius: 5px;
}

.rttedImgRight {
  height: 11px;
  width: 13px;
}

.cookieesss p {
  font-size: 15px;
}

.OnOFtoggl {
  height: 100%;
  width: 100%;

  position: relative;
  padding-right: 0.5rem;
}

.tggle {
  position: absolute;
  right: 10px;
  bottom: 13px;
}

.crssImgClss,
.righImgClss {
  position: relative;
}

.crssImgClss img {
  position: absolute;
  top: 30px;

  left: 8px;
  height: 9px;
  width: 10px;
  z-index: 20;
}

.CrsssImgg img {
  position: absolute;
  right: 7px;
  top: 2rem;
  height: 15px;
  width: 18px;
  z-index: 77;
}

.righImgClss img {
  position: absolute;
  right: 7px;
  top: 30px;

  height: 9px;
  width: 10px;
  z-index: 21;
}

.tggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.tggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 45px;
  height: 22px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tggle label:after {
  content: "";
  position: absolute;
  cursor: pointer;
  top: 3px;
  left: 5px;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 90px;
  z-index: 999;
  transition: 0.3s;
}

.tggle input:checked+label {
  /* background: #f58634; */
  background-color: #163b8a;
}

.tggle input:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.tggle label:active:after {
  width: 13px;
}

.welcome_bg {
  background-size: 90% 90%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
