.notFoundCont {
  margin-top: 6rem !important;
}

.gameDiv {
  height: 500px;
}

.defaultCanvas0 {
  width: 100% !important;
  height: 100% !important;
}


