.brand_img {
  width: 180px;
}

.brand_img img {
  width: 100%;
}

.headerBtns {
  display: flex;
  /* align-items: center; */
  gap: 1.5rem;
}

#navbarText {
  gap: 2.5rem;
}

#navbarText ul {
  margin-left: auto !important;
  gap: 1.5rem;
}

#navbarText ul li a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
}

.careerbTNhEADER {
  background-color: transparent;
  z-index: 0;
  padding: 0.4rem 1.2rem;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  border-color: transparent;
  color: #ffffff;
  font-weight: 500;
}

.careerbTNhEADER::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -70%;
  top: -70%;
  width: 250%;
  height: 550%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%100%, 50%50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(
    270deg,
    #76ff01 0.73%,
    #edff0b 18%,
    #15ff3e 33.06%,
    #17ffff 49.89%,
    #1700f5 67.61%,
    #ec00e7 85.77%
  );
  animation: bgRotate 4s linear infinite;
}

.careerbTNhEADER::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: black;
  border-radius: 20px;
}

@keyframes bgRotate {
  100% {
    transform: rotate(1turn);
  }
}

.contactBtnheader {
  font-weight: 500;
  color: white !important;
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  border-radius: 20px;
  padding: 0.4rem 1.1rem;
  border: 0 none transparent;
  height: 100%;
}

.navbarPAdding {
  padding: 0 3rem;
}

.bg_lightblack {
  background: rgb(28, 28, 28, 0.5);
}

.navbar-toggler {
  padding: 0.3rem 0.6rem !important;
  border: 1px solid #ffffff !important;
}

.sbmtbtnDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navStickyTop {
  width: 100%;
  position: fixed !important;
  top: 0;
  z-index: 990;
}

@media screen and (max-width: 768px) {
  .brand_img {
    width: 150px;
  }

  .forntBannerP {
    font-size: 14px;
    margin: 1.1rem 0;
    text-shadow: 2px 6px 8px rgba(0, 0, 0, 0.8);
  }
}

@media screen and (max-width: 991px) {
  .navStickyTop {
    position: sticky !important;
  }
}

@media screen and (max-width: 1050px) {
  .navbarPAdding {
    padding: 0 2rem;
  }

  #navbarText ul {
    gap: 15px;
  }
}

@media screen and (max-width: 576px) {
  .navbarPAdding {
    padding: 0 1rem;
  }

  .forntBannerP {
    font-size: 14px !important;
    margin: 1rem 0;
    text-shadow: 2px 6px 8px rgba(0, 0, 0, 0.8);
  }
}
@media screen and (max-width: 1129px) {
  .navbarPAdding {
    padding: 0 0.6rem;
  }
  #navbarText {
    gap: 1.1rem;
  }
  #navbarText ul {
    gap: 1rem;
  }
}

.navDropdownDIv {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
}

.dropdown-menu[data-bs-popper] {
  top: 140% !important;
  background-color: #000;
}

.dropdown-menu[data-bs-popper] a:hover {
  color: #ffffff !important;
  background-color: transparent !important;
}

.scrolled-header {
  background-color: #000000 !important;
  transition: background-color 0.3s ease !important;
}

.navIemTextSelected {
  background: linear-gradient(90deg, #eb6a30 2.27%, #e547e7 94.51%) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.FloatingDiv .float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  z-index: 1000;
  animation: bot-to-top 2s ease-out;
}

/*
 * Submenu - Buttons
 */
.FloatingDiv ul {
  position: fixed;
  right: 40px;
  bottom: 90px;
  padding: 1rem 1.5rem !important;
  z-index: 100;
  border-radius: 15px;
  background-color: #ffffff;
}

/* Hidden Buttons */
.FloatingDiv a.float + ul {
  visibility: hidden;
}

/* Visible Buttons */
.FloatingDiv a.float:hover + ul {
  visibility: visible;
  animation: scale-in 0.5s;
}

.FloatingDiv ul:hover {
  visibility: visible !important;
}

/* Center icons */
.FloatingDiv a i.icon {
  font-size: 24px;
  margin-top: 18px;
}

/*
 * Animations
 */
.FloatingDiv a.float i {
  animation: rotate-in 0.5s;
}

.FloatingDiv a.float:hover > i {
  animation: rotate-out 0.5s;
}

@keyframes bot-to-top {
  0% {
    bottom: -60px;
  }

  50% {
    bottom: 40px;
  }
}

@keyframes scale-in {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotate-in {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-out {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.chatboxHead {
  font-weight: 500;
  background: linear-gradient(90deg, #eb6a30 2.27%, #e547e7 94.51%) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
  border-bottom: 2px solid #cdcdcd;
  font-size: 18px;
}

.flaotingLinks img {
  width: 1.3rem;
}

.flaotingLinks p {
  color: #484848;
  font-weight: 600 !important;
  margin: 0;
}

.flaotingLinks {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
}

.captch_select {
  user-select: none;
  /* Disable text selection */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.scrollDown {
  position: fixed;
  bottom: 18%;
  right: 3%;
  animation: bot-to-top1 1.5s ease-out infinite;
  z-index: 10;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .scrollDown {
    position: fixed;
    bottom: 5%;
    right: 10%;
    animation: bot-to-top1 1.5s ease-out infinite !important;
    z-index: 10;
    cursor: pointer;
  }

  .scrollUp {
    position: absolute;
    bottom: 7% !important;
    right: 6% !important;
    animation: none !important;
    z-index: 10;
    cursor: pointer;
  }
}

.scrollUp {
  position: absolute;
  bottom: 18%;
  right: 3%;
  animation: bot-to-top2 1.5s ease-out infinite;
  z-index: 10;
  cursor: pointer;
}

.scrollDown img {
  width: 2.5rem;
  animation: hueEffect 6s infinite;
}

@keyframes bot-to-top1 {
  0% {
    bottom: 18%;
  }

  50% {
    bottom: 15%;
  }

  100% {
    bottom: 18%;
  }
}

@keyframes bot-to-top2 {
  0% {
    bottom: 18%;
  }

  50% {
    bottom: 15%;
  }

  100% {
    bottom: 18%;
  }
}

.dropdown_header::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -70%;
  top: -70%;
  width: 250%;
  height: 550%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%100%, 50%50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(
    270deg,
    #76ff01 0.73%,
    #edff0b 18%,
    #15ff3e 33.06%,
    #17ffff 49.89%,
    #1700f5 67.61%,
    #ec00e7 85.77%
  );
  animation: bgRotate 4s linear infinite;
}
