.company {
  background-color: white;
  color: black;
}

.portfolio_bg {
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
}

.port_img_bg {
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  margin: 3rem 0;
}

@media screen and (max-width: 1200px) {
  .port_img_bg {
    height: 80vh;
  }
}

@media screen and (max-width: 1100px) {
  .port_img_bg {
    height: 70vh;
  }
}

@media screen and (max-width: 991px) {
  .port_img_bg {
    height: 40vh;
  }
  .padding0 {
    padding: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .port_img_bg {
    height: 35vh;
  }

  .txti_m_worxds,
  .txti_m {
    width: 96% !important;
  }

  .ct_bot {
    padding: 0 12px !important;
  }
}

@media screen and (max-width: 576px) {
  .port_img_bg {
    height: 30vh;
  }
}

.port_txt_bg h1 {
  font-size: 39.47px !important;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
  text-align: center;
  color: #ffffff !important;
}

.ct_bot {
  align-items: center;
  padding: 100px;
}

.cht_bot_txt h5 {
  text-align: center;
  font-size: 32px;
  margin: 3rem 0;
}

.txti_m {
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  border-radius: 20px;
  padding: 20px;
  align-items: center;
  width: 80%;
  margin: 2rem auto;
}

.img_tsts img {
  border-radius: 25px;
}

.txti_m_worxds {
  padding: 20px;
  width: 80%;
  margin: 2rem auto;
}

.image_width_100_per {
  width: 100%;
}
.show_back_color {
  background: black;
  color: white;
}
.color_heading_p {
  font-size: 22px;
  font-weight: 700;
}
.color_black_study {
  color: black;
  font-weight: 500;
}
.color_heading {
  color: gray;
  font-weight: 600;
}
.color_first {
  color: #d05f1f;
  font-weight: 500;
}
.color_second {
  color: #c316df;
  font-weight: 500;
}
