@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tilt+Neon&display=swap");

.pagenotfoundtxt {
  width: 60%;
  top: 16%;
  left: 20%;
  position: absolute;
}
.pagenotfoundtxt h1 {
  font-size: 5.5rem;
  font-weight: bold;
  color: white;
}
.pagenotfoundtxt p {
  margin-top: 10px;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
}
.gameDiv {
  position: relative;
}
.notFoundCont {
  position: relative;
  text-align: center;
}

.gameDiv {
  background-color: transparent;
  z-index: 0;
  padding: 0.1rem 0.1rem;
  overflow: hidden;
  position: relative;

  border-color: transparent;
  color: #ffffff;
  font-weight: 500;
}

.gameDiv::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -70%;
  top: -70%;
  width: 200%;
  height: 550%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%100%, 50%50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(
    270deg,
    #76ff01 0.73%,
    #edff0b 18%,
    #15ff3e 33.06%,
    #17ffff 49.89%,
    #1700f5 67.61%,
    #ec00e7 85.77%
  );
}

.gameDiv::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: black;
}
.GamePlayBtn button {
  background-color: black;
  border: none;
}
.GamePlayBtn img {
  height: 120px;
  width: 120px;
}

.IntrUctionCont {
  width: 100%;
}

.IntrUctionCont ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.intstructionnn li {
  font-size: 12px;
  padding-right: 10px;
  display: inline;
  white-space: nowrap;
}
.intstructionnn img {
  margin-right: 5px;
  width: 15px;
  height: 15px;
}

.leftKeYY {
  transform: rotate(-90deg);
}
.rightKeYY {
  transform: rotate(90deg);
}

