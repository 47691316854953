.frontBannerBg {
  width: 100%;
  display: flex;
  align-items: center;
  animation: hueEffect 6s infinite;
  height: 100vh;
}

@media screen and (max-width: 991px) {
  .frontBannerBg {
    height: auto !important;
  }
}

@media screen and (max-width: 576px) {
  .frontBannner_container {
    position: relative;
    height: 70vh !important;
  }

  .slick-slider,
  .slick-track,
  .slick-list,
  .frntBannerText_container {
    height: 100% !important;
  }

  .frntBannerText_container {
    position: static !important;
    top: auto !important;
    left: auto !important;
    transform: none !important;
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 3rem;
  }

  .frontBannerBg .firstImg {
    object-position: 85%;
  }

  .frontBannerBg .secondImg {
    object-position: 95%;
  }

  .whatIsAI_container {
    margin-top: 4rem !important;
  }

  .forntBannerH1 {
    text-align: center;
  }
  

  .forntBannerP {
    text-align: center;
  }

  .buttonFrontGet {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .frontBannner_container {
    position: relative;
    height: 80vh !important;
  }
}

.frontBannner_container {
  position: relative;
}

.frntBannerText_container {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0%, -50%);
}

.frontBannerBg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.forntBannerH1 {
  font-weight: 900 !important;
  font-size: 60px;
}

.forntBannerH12 {
  font-weight: 400 !important;
}

.forntBannerP {
  margin: 1.5rem 0;
  text-shadow: 2px 6px 8px rgba(0, 0, 0, 0.8);
}

.background-cover {
  background: transparent;
}

.slide {
  height: 100%;
}

/* ---------------------------------------------------------------------------------------------------- */

.whatIsAI_bg {
  border-radius: 20px;
  background-color: #33afc6;
  padding: 0 1rem;
  height: 100%;
  width: 100%;
  position: relative;
}

.whatIsAI {
  width: 100%;
  transform: translate(0%, -10%);
}

.whatIsAI {
  background-color: #242424;
  border-radius: 20px;
}

.whatIsAIH1 {
  font-weight: 400 !important;
  margin-bottom: 0;
}

.whatIsAIH1-2 {
  margin-bottom: 1.5rem;
}

.whatIsAI_text {
  padding-left: 2rem;
}

.whatIsAI_textCOntainer h6 {
  font-weight: 400 !important;
  line-height: 1.5rem;
}

.whatIsAI_text img {
  width: 2rem;
  margin-bottom: 1rem;
}

.whatIsAI_text {
  height: 100% !important;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.whatIsAI_Img {
  width: 100%;
  height: 100%;
  position: relative;
}

.whatIsAI_ImgDiv {
  width: 100%;
  height: 100%;
}

.whatIsAI_Img img {
  width: 100%;
  position: absolute;
  bottom: 0%;
  transform: translate(0%, 0%);
}

.whatIsAI_container {
  margin-top: 25rem;
}

@media screen and (max-width: 1400px) {
  .whatIsAI_container {
    margin-top: 18rem;
  }
}

@media screen and (max-width: 1200px) {
  .whatIsAI_text {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .whatIsAI_container {
    margin-top: 12rem;
  }
}

@media screen and (max-width: 991px) {
  .whatIsAI_text {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .whatIsAI_container {
    margin-top: 18rem;
  }

  .whatIsAIH1-2,
  .whatIsAIH1 {
    font-size: 24px;
  }

  .whatIsAI_textCOntainer h6 {
    font-size: 15px;
    line-height: 1.2rem;
  }
}

@media screen and (max-width: 900px) {
  .whatIsAI_container {
    margin-top: 15rem;
  }

  .frntBannerText_container {
    left: 5% !important;
  }
}

@media screen and (max-width: 778px) {
  .whatIsAIH1-2,
  .whatIsAIH1 {
    font-size: 22px;
  }

  .frntBannerText_container {
    position: absolute;
    top: 40%;
    transform: translate(2%, -40%);
  }
}

@media screen and (max-width: 768px) {
  .whatIsAI_Img img {
    position: static;
  }

  .whatIsAIImgCont {
    margin-right: 0;
    margin-left: auto;
  }

  .whatIsAI {
    width: 100%;
    transform: translate(0%, -6%);
  }
}

@media screen and (max-width: 650px) {
  .forntBannerH12,
  .forntBannerH1 {
    font-size: 26px !important;
  }

  .forntBannerP {
    margin: 10px 0 !important;
    font-size: 15px;
    text-shadow: 2px 6px 8px rgba(0, 0, 0, 0.8);
  }

  .frntBannerText_container {
    position: absolute;
    top: 40%;
    left: 2% !important;
    transform: translate(0%, -40%);
  }

  .whatIsAI_container {
    margin-top: 8rem;
  }
}

@media screen and (max-width: 576px) {
  .frontBannner_container {
    height: 330px;
  }

  .frontBannerBg img {
    height: 330px !important;
    object-fit: cover;
  }

  .whatIsAI_text {
    padding-left: 0.8rem !important;
    padding-right: 0.8rem;
    padding-bottom: 1rem;
  }
}

/* --------------------------------------------------------------------------------------------------------------- */

.services {
  margin: 5rem 0;
}

.servicesDiv {
  border-radius: 23px;
  border: 1px solid #33afc6;
}

.servicesDiv_left {
  background: linear-gradient(90deg, #33afc6 1.89%, #003843 93.07%),
    linear-gradient(269.75deg, #33afc6 1.59%, #003843 94.97%);
  padding: 1.5rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.servicesList {
  background: linear-gradient(90deg, #015161, #003843);
  border-radius: 7px;
}

.servicesList ul {
  padding: 0;
  list-style: none;
  padding: 1rem;
  margin-bottom: 0;
}

.servicesList ul .servicesList_items {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  border-bottom: 1px solid #ffffff;
  width: 80%;
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .servicesList ul .servicesList_items {
    width: 100%;
    gap: 0.5rem;
  }

  .selectedServicesDiv p {
    font-size: 13px;
  }
}

.slctdItem {
  opacity: 1 !important;
}

.servicesList ul .servicesList_items p {
  font-weight: 500;
}

.servicesList ul li {
  margin-bottom: 1rem;
}

.selectedServicesDiv img {
  width: 3rem;
}

.selectedServicesDiv p {
  margin: 0;
}

.selectedServicesDiv h4 {
  margin-bottom: 1.2rem;
}

.servicesDiv_right {
  display: flex;
  align-items: center;
  padding: 2rem;
}

.services_contaINER h2 {
  margin: 3rem 0;
}

@media screen and (max-width: 576px) {
  .servicesDiv .row {
    flex-direction: column-reverse;
  }

  .servicesDiv_left {
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0px !important;
  }

  .servicesDiv_left {
    background: linear-gradient(90deg, #33afc6 1.89%, #003843 93.07%),
      linear-gradient(269.75deg, #33afc6 1.59%, #003843 94.97%);
    padding: 1.5rem;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .servicesList {
    background: linear-gradient(90deg, #015161, #003843);
    border-radius: 7px;
  }
}

/* ------------------------------------------------------------------------------------------------------------ */

.techStack_CONTAINER h2 {
  text-align: center;
  margin: 4rem auto;
}

.tectStack_listItem img {
  width: 50%;
  margin-bottom: 0.5rem;
}

.tectStack_listItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transition: transform 0.3s ease;
}
.applLogo img {
  border-radius: 10px;
  border: 1.5px solid white !important;
}

.tectStack_listItem:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 1200px) {
  .tectStack_listItem p {
    font-size: 15px;
  }

  .tectStack_listItem {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 940px) {
  .tectStack_listItem p {
    font-size: 14px;
  }

  .tectStack_listItem {
    padding: 0;
  }
}

@media screen and (max-width: 797px) {
  .tectStack_listItem p {
    font-size: 13px;
  }

  .tectStack_listItem {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .tectStack_listItem p {
    font-size: 14px;
  }

  .tectStack_listItem {
    padding: 0;
  }
}

@media screen and (max-width: 410px) {
  .tectStack_listItem p {
    font-size: 13px;
  }

  .tectStack_listItem {
    padding: 0;
  }
}
.card_container {
  position: relative;
}

.LeftRightButtnn {
  display: flex;

  justify-content: space-between;
}
.LeftRightButtnn button {
  position: absolute;

  z-index: 99;
}
.LeftRightButtnn button,
.LeftRightButtnn img {
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: none;
}
.rightBtnn {
  position: absolute;
  right: 0px;
  top: 10rem;
}
.LeftBtnn {
  position: absolute;
  top: 10rem;
  left: -20px;
}
.rightBtnn img {
  transform: rotate(180deg);
}
.slick-prev,
.slick-next {
  display: none !important;
  background-color: transparent;
}
.slick-dots li.slick-active button:before {
  color: white !important;
}
.slick-dots li button:before {
  color: white !important;
}
.slick-dots {
  position: absolute;
  bottom: 7.7% !important;
}
@media screen and (max-width: 550px) {
  .rightBtnn {
    position: absolute;
    right: 4%;
    top: 29%;
  }
  .LeftBtnn {
    position: absolute;
    top: 29%;
    left: -8.5%;
  }
}
/* ---------------------------------------------------------------------------------------------------------- */
.clientLeft {
  background-image: url("../assets/images/clientstory1.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
  padding: 2rem 1rem;
}

.clientLeft::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
}

.clientLeft h4 {
  z-index: 1;
  position: relative;
  top: 40%;
}

.rightTextDiv {
  background-color: #01e3eb;
}

.rightTextDiv1 {
  background-color: #97bf0e;
}

.rightImgDiv img {
  width: 100%;
}

.rightTextDiv1,
.rightTextDiv {
  display: flex;
  align-items: center;
  padding: 1rem;
}
image .clientStoryDiv {
  margin: 3rem 0;
}

@media screen and (max-width: 768px) {
  .clientLeft h4 {
    font-size: 1.3rem;
    z-index: 1;
    position: relative;
    top: 0%;
    left: auto;
    right: auto;
  }

  .forntBannerH1,
  .forntBannerP {
    text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
  }
  .rightTextDiv h4,
  .rightTextDiv1 h4 {
    font-size: 13px;
  }
}

@media screen and (max-width: 576px) {
  .rightTextDiv h5,
  .clientLeft h5 {
    font-size: 16px;
  }

  .industries_listItem p {
    font-size: 8px;
  }
}

/* ------------------------------------------------------------------------------------------------------------- */

.industries_head {
  text-align: center;
  margin: 3rem 0;
}

.industries_listcontainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.industries_listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 93px;
}

.industries,
.processAndPlanning {
  margin: 4rem 0;
}

.dp_text {
  margin-bottom: 4rem;
}

.home_dp {
  margin-bottom: 1.5rem;
}

.hme_img {
  width: 85%;
  max-height: 250px;
  height: 100%;
  border-radius: 200px !important;
  position: relative;
}

.dp_txt {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ourPartners,
.ttoesection {
  margin: 3rem 0;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.frontScreenBtns {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.frontScreenBtns button {
  border: none;
  outline: none;
  background-color: transparent;
  color: #ffffff;
  font-weight: 500;
}

.slickButtonSliderActive {
  background: linear-gradient(90deg, #eb6a30 2.27%, #e547e7 94.51%) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
  font-weight: 600 !important;
}

.hd_hm {
  margin: auto;
  font-size: 43px;
  text-align: center;
}

.home_img {
  position: relative;
}

.home_img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  transition: background-color 0.3s;
  /* Optional: Add a smooth transition effect */
}

.home_img:hover::after {
  background-color: rgba(0, 0, 0, 0);
}

.socialIcnsonScree img {
  width: 2.3rem;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 0 rgba(255, 229, 181, 0.8);
  animation: glow 2s infinite;
}

.socialIcnsonScree ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: fixed;
  top: 40%;
  z-index: 999999;
  padding: 1rem !important;
  list-style: none;
}

.socialIcnsonScree ul li {
  transition: 0.3s all ease;
  cursor: pointer;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }

  50% {
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.5);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
}

.socialIcnsonScree ul li:hover {
  transform: scale(1.1);
}

::-webkit-scrollbar {
  width: 5px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
}

@media screen and (max-width: 489px) {
  .industries_listItem {
    width: 80px;
    font-size: 14px;
  }
}

/* @media screen and (max-width: 489px) {
  .frontScreenBtns {
     display: none;
  }
} */

@media screen and (max-width: 424px) {
  .industries_listItem {
    width: 50px;
    font-size: 7px;
  }

  .rowReversehome {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 786px) {
  .whatIsAI_Img img {
    width: 60% !important;
  }

  .frontScreenBtns {
    margin-top: 10px;
  }

  .frontScreenBtns {
    gap: 10px !important;
  }

  .frontScreenBtns button {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 576px) {
  .frontScreenBtnsdisplay {
    display: none;
  }

  .btnQuotesmall {
    padding: 0.4rem 1rem !important;
    font-size: 14px;
  }

  .btnQuotesmall .right_arrow svg {
    width: 1.1rem;
  }

  .FloatingDiv .float {
    position: fixed;
    bottom: 30px;
    right: 25px;
    height: 50px;
    width: 50px;
  }

  .FloatingDiv .float img {
    width: 100%;
  }

  .FloatingDiv ul {
    right: 30px;
    bottom: 80px;
  }
}

@media screen and (min-width: 576px) {
  .forntbtnsShowdiv {
    display: none;
  }
}

.forntbtnsShowdiv {
  justify-content: center;
  display: none;
}

@media screen and (max-width: 400px) {
  .forntbtnsShowdiv button {
    font-size: 12px !important;
  }

  .forntbtnsShowdiv {
    gap: 0 !important;
  }
}
.marquee-container{
 white-space: nowrap;
 overflow: hidden;
}
.marquee-container:hover .marquee-image{
  animation-play-state: paused;

}
.marquee-container::before{
  position: absolute;
}
@keyframes slide {
  from{
  transform: translateX(0);
  }
  to{
  transform: translateX(-100%);
  }
}
.marquee-image{
  display: inline-block;
  animation: 25s slide infinite linear;
}
.marquee-image img{
  cursor: pointer;
  height: 5rem;
  width: 5rem;
  border: 1px solid white;
  padding: 2px 4px;
  border-radius: 8px;
  object-fit: contain;
  margin-left: 20px;
  background: white;
}

